import { StyleRules, StepLabelClasskey } from '@material-ui/core';
import colors from '../colors.module.scss';

export const MuiStepLabel: Partial<StyleRules<StepLabelClasskey, {}>> = {
  label: {
    color: 'inherit',
    fontWeight: 700,
    '&$active, &$completed': {
      fontWeight: 700,
    },
    '&$active': {
      color: colors.primary,
    },
  },
};
