import { firestore } from '../types/firebase';

export interface Verification {
  createdAt: firestore.Timestamp;
  options: VerificationOptions;
  redirectionPath?: string;
  type: VerificationType;
  uid: string;
}

export enum VerificationType {
  CHANGE_ORGANIZATION_SPECIFIC_EMAIL = 'change-organization-specific-email',
  CHANGE_USER_EMAIL = 'change-user-email',
}

export interface ChangeOrganizationSpecificEmailOptions {
  email: string;
  organizationId: string;
  userId: string;
}

export interface ChangeUserEmailOptions {
  email: string;
  userId: string;
}

export type VerificationOptions =
  | ChangeOrganizationSpecificEmailOptions
  | ChangeUserEmailOptions;
