import { Typography } from '@material-ui/core';
import React from 'react';
import styles from './Loading.module.css';

export const Loading = ({ overlay = false, text = '', className = '' }) => (
  <div
    className={`${styles.container} ${
      overlay ? styles.overlay : ''
    } ${className} `}
  >
    <div className={styles.loader}></div>
    <Typography variant="h6" className={styles.text}>
      {text}
    </Typography>
  </div>
);
