import { firestore } from '../types/firebase';

enum NotificationType {
  NewGame = 'newGame',
  TotalScore = 'totalScore',
}

type Notification = {
  uid: string;
  userId: string;
  deleted?: boolean;
  read?: boolean;
  created?: firestore.Timestamp;
  title: string;
  content: string;
  link?: string;
  linkTitle?: string;
  type?: NotificationType;
  details?: object;
};

type NotificationVariant = 'page' | 'popper';

export { NotificationType };
export type { Notification, NotificationVariant };
