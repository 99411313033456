import { ThemeOptions, createTheme } from '@material-ui/core/styles';
import { BetterStepIcon } from 'components/shared/BetterMuiStepIcon/BetterMuiStepIcon';
import colors from 'styles/colors.module.scss';
import {
  /* PLOP_INJECT_IMPORT */
  MuiTableRow,
  MuiDialog,
  MuiFormLabel,
  MuiTooltip,
  MuiInputLabel,
  MuiTableCell,
  MuiMenuItem,
  MuiMenu,
  MuiDivider,
  MuiSwitch,
  MuiStepIcon,
  MuiStepConnector,
  MuiStepLabel,
  MuiButton,
  MuiInputBase,
  MuiAppBar,
  MuiToolbar,
  MuiDrawer,
  MuiAvatar,
  MuiBadge,
  MuiListItem,
  MuiListItemText,
  MuiListItemIcon,
  MuiChip,
  MuiPaper,
  MuiTextField,
} from './overrides';

export const themeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: colors.primary,
      light: colors.primaryLight,
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondaryLight,
    },
    quaternary: {
      main: colors.quaternary,
      light: colors.quaternaryLight,
      contrastText: colors.white,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
    },
    info: {
      main: colors.info,
    },
    success: {
      main: colors.success,
      contrastText: colors.white,
      dark: colors.successDark,
    },
    background: {
      default: colors.background,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontWeightRegular: 400,
    fontFamily: [
      'Mulish',
      'Open Sans',
      'Lato',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    allVariants: {
      fontWeight: 400,
      lineHeight: 1,
    },
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 32,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 18,
    },
    h6: {
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 12,
    },
    subtitle2: {
      fontSize: 14,
    },
    button: {
      fontSize: '1rem',
      lineHeight: 1.25,
      textTransform: 'capitalize',
      borderRadius: '4px',
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ms: 696,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiButton: {
      variant: 'outlined',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiStepLabel: {
      StepIconComponent: BetterStepIcon,
    },
    MuiTextField: {
      color: 'primary',
      variant: 'outlined',
    },
    MuiChip: {
      color: 'primary',
      size: 'small',
    },
    MuiMenu: {
      elevation: 0,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      getContentAnchorEl: null,
    },
  },
  overrides: {
    /* PLOP_INJECT_OVERRIDE */
    MuiTableRow,
    MuiDialog,
    MuiFormLabel,
    MuiTooltip,
    MuiInputLabel,
    MuiTableCell,
    MuiMenuItem,
    MuiMenu,
    MuiDivider,
    MuiSwitch,
    MuiStepIcon,
    MuiStepConnector,
    MuiStepLabel,
    MuiButton,
    MuiInputBase,
    MuiAppBar,
    MuiToolbar,
    MuiDrawer,
    MuiAvatar,
    MuiBadge,
    MuiListItem,
    MuiListItemText,
    MuiListItemIcon,
    MuiChip,
    MuiPaper,
    MuiTextField,
  },
};

export const theme = createTheme(themeOptions);

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    ms: true;
  }
}
