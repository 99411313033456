import { StyleRules, StepIconClasskey } from '@material-ui/core';
import colors from '../colors.module.scss';

const ICON_WIDTH = 44;

export const MuiStepIcon: Partial<StyleRules<StepIconClasskey, {}>> = {
  root: {
    width: ICON_WIDTH - 2 * 2,
    height: ICON_WIDTH - 2 * 2,
    '& text': {
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 30,
      fill: colors.gray300,
      transform: 'translate(0px, 2px)',
    },
    border: `2px solid ${colors.gray300}`,
    borderRadius: ICON_WIDTH,
    '& circle': {
      color: colors.white,
    },
  },
  active: {
    '& text': {
      fill: colors.primary,
    },
    borderColor: colors.primary,
  },
  completed: {
    border: 'none',
    borderColor: colors.primary,
    width: ICON_WIDTH,
    height: ICON_WIDTH,
  },
};
