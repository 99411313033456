import { OrganizationRole } from '../models/OrganizationMember';

export function checkIfUserHasRoleInOrganization(
  userRoles: OrganizationRole | OrganizationRole[] = [],
  roles: OrganizationRole[] = []
) {
  if (Array.isArray(userRoles)) {
    return userRoles.some((userRole) => roles.includes(userRole));
  }

  return roles.includes(userRoles);
}
