import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-firestore';
import 'firebase/firebase-functions';
import 'firebase/firebase-database';
import 'firebase/firebase-storage';
import 'firebase/remote-config';

import { config } from './config/index';
import { firebaseConfig } from './environment';
import configDefaults from './configDefaults.json';

const TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp();
const RTDB_TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;
const FIELD_DELETE = firebase.firestore.FieldValue.delete();
const UID = firebase.firestore.FieldPath.documentId();
const GeoPoint = firebase.firestore.GeoPoint;
type Transaction = firebase.firestore.Transaction;
const ARRAYS_TIMESTAMP = firebase.firestore.Timestamp.now();
const getFieldIncrementPlaceholder = firebase.firestore.FieldValue.increment;

// Your web app's Firebase configuration
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const rtDb = firebase.database();
const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();
const remoteConfig = firebase.remoteConfig();

const getGoogleProvider = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');

  return provider;
};

const getMicrosoftProvider = () => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  provider.addScope('profile');
  provider.addScope('email');

  return provider;
};

if (config.dbHost && config.dbHost.includes('localhost')) {
  const firestoreSettings: firebase.firestore.Settings = {
    host: config.dbHost,
    ssl: false,
  };

  if (window.Cypress) {
    // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    firestoreSettings.experimentalForceLongPolling = true;
  }

  db.settings(firestoreSettings);
  functions.useEmulator('localhost', 5001);
  auth.useEmulator('http://localhost:9099');
  storage.useEmulator('localhost', 9199);
}

remoteConfig.defaultConfig = {
  config: JSON.stringify(configDefaults),
};

remoteConfig.settings = {
  minimumFetchIntervalMillis: Number(config.remoteConfig.minFetchInterval),
  fetchTimeoutMillis: Number(config.remoteConfig.fetchTimeout),
};

export default firebase;
export {
  db,
  rtDb,
  auth,
  functions,
  storage,
  remoteConfig,
  TIMESTAMP,
  UID,
  RTDB_TIMESTAMP,
  FIELD_DELETE,
  GeoPoint,
  ARRAYS_TIMESTAMP,
  getFieldIncrementPlaceholder,
  getGoogleProvider,
  getMicrosoftProvider,
};
export type { Transaction };
