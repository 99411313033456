import { firestore } from '../types/firebase';

export interface OrganizationGroup {
  uid: string;
  name: string;
  members: OrganizationGroupMember[];
  createdAt: firestore.Timestamp;
  updatedAt: firestore.Timestamp;
}

export enum OrganizationGroupMemberRole {
  admin = 'Admin',
  player = 'Player',
}

export interface OrganizationGroupMember {
  id: string;
  name: string;
  email: string;
  username: string;
  status: OrganizationGroupMemberStatus;
  role: OrganizationGroupMemberRole;
}

export enum OrganizationGroupMemberStatus {
  Active = 'active',
  Invited = 'invited',
  Request = 'request',
}
