import { ActiveGame, Friend, GameStatus, User } from '../models';

const generateIntegerFromRange = (from: number, to: number) =>
  Math.floor(Math.random() * to) + from;

const areUsersFriends = (currentUser: User, potentialFriendId: string) => {
  const { friends } = currentUser;
  if (!friends?.length) return false;

  return !!friends.find(
    (friend: Friend) => friend.userUid === potentialFriendId
  );
};

const gameNeedsEvaluation = (game: ActiveGame) =>
  game.status === GameStatus.Completed &&
  (game.hasFreeText || game.hasPhoto || game.hasVideo);

export { generateIntegerFromRange, areUsersFriends, gameNeedsEvaluation };
export * from './sortPlayersOnPodium';
export * from './splitArrayToChunks';
export * from './checkIfUserHasRole';
export * from './checkIfUserHasRoleInOrganization';
export * from './game';
export * from './user';
export * from './collection';
export * from './organization';
