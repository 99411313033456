import React from 'react';
import { AppRouter } from 'router/AppRouter';
import { StateMachineProvider } from 'little-state-machine';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import { Alerts } from 'components/Alerts/Alerts';
import { createStore } from 'stores/createStore';
import { config } from './config/index';

createStore();

const gtmParams = {
  id: config.gtmId || '',
  injectScript: config.gtmId && !window.Cypress,
};

const App = () => {
  return (
    <StateMachineProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Alerts />
        <GTMProvider state={gtmParams}>
          <AppRouter />
        </GTMProvider>
      </MuiPickersUtilsProvider>
    </StateMachineProvider>
  );
};

export default App;
