import { Config } from '@shared/models/Config';
import environmentConfig from 'config';
import configDefaults from 'configDefaults.json';
import { remoteConfig } from '../firebase';

const onlyLocalConfig =
  environmentConfig.dbHost?.includes('localhost') &&
  environmentConfig.enableConfigFromRemote !== 'true';

const getAll = async () => {
  if (onlyLocalConfig) {
    process.env.NODE_ENV !== 'test' &&
      console.warn('[Config] Using only local config');

    return configDefaults;
  }

  const activated = await remoteConfig.fetchAndActivate();
  if (!activated) {
    console.warn('[Config] Remote config was not activated...');
  }
  const { config } = remoteConfig.getAll();
  const mergedConfig = { ...configDefaults, ...JSON.parse(config.asString()) };

  return mergedConfig as Config;
};

export const ConfigService = {
  getAll,
};
