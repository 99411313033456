import { firestore } from '../types/firebase';
import { Asset } from './Asset';

export type Task =
  | Mc
  | FreeText
  | Photo
  | Video
  | ArOrder
  | ArCategorize
  | ArCustom
  | ArMc;

export type TaskTypeToSelect = ResponseType | 'Confirmation' | 'Business AR';

export enum ResponseType {
  MultipleChoice = 'Multiple Choice',
  FreeText = 'Free text',
  Photo = 'Photo',
  Video = 'Video',
  Ar = 'AR',
}

export enum ArType {
  Order = 'Order',
  Categorize = 'Categorize',
  Mc = 'Multiple Choice',
  Custom = 'Custom',
}

export enum ArTypeSearchParams {
  Order = 'order',
  Categorize = 'categorize',
  Mc = 'mc',
  Custom = 'custom',
}

type TaskCommonFields = {
  uid: string;
  title: string;
  description?: string;
  responseType: ResponseType;
  points?: number;
  active: boolean;
  created: firestore.Timestamp;
  question: string;
  questionScore: number | string;
  questionURL: string;
  tags: string[];
  updated: firestore.Timestamp;
  userFirstName: string;
  userId: string;
  userLastName: string;
  alternatives?: any[];
  isFavorite?: boolean;
  preTaskInfo?: TaskInformation | any;
};

type TaskInformation = {
  name: string;
  description: string;
  fileUrl?: string;
  fileType?: string;
};

type McFields = {
  correct: boolean;
  alternatives: Alternative[];
  randomizeAlternatives: boolean;
  isQuestionScore: boolean;
};

type Mc = TaskCommonFields &
  McFields & {
    responseType: ResponseType.MultipleChoice;
  };

type FreeText = TaskCommonFields & {
  responseType: ResponseType.FreeText;
  freeTextAnswer: string;
};

type Photo = TaskCommonFields & {
  responseType: ResponseType.Photo;
  photoAnswer: string;
};

type Video = TaskCommonFields & {
  responseType: ResponseType.Video;
  videoAnswer: string;
};

type ArCommonFields = TaskCommonFields & {
  arTemplate: number;
  arType: ArType;
};

type ArOrder = ArCommonFields & {
  responseType: ResponseType.Ar;
  correct: boolean;
  arType: ArType.Order;
  alternatives: OrderAlternative[];
  welcomeText: string;
};

type ArCategorize = ArCommonFields & {
  responseType: ResponseType.Ar;
  alternatives: CategorizeAlternative[];
  correct: boolean;
  arType: ArType.Categorize;
  category1: string;
  category2: string;
  welcomeText: string;
};

type ArCustom = ArCommonFields & {
  responseType: ResponseType.Ar;
  arType: ArType.Custom;
  arAsset: Asset;
};

type ArMc = ArCommonFields &
  McFields & {
    responseType: ResponseType.Ar;
    arType: ArType.Mc;
    alternatives: MCAlternative[];
    welcomeText: string;
  };

export interface MCAlternative extends Alternative {
  correct: boolean;
  index: number;
  points: number;
  percentage: number;
}

export interface OrderAlternative extends Alternative {
  index: number;
}
export interface CategorizeAlternative extends Alternative {
  categoryId: number;
}

interface Alternative {
  answerText: string;
}

export function isArCategorize(task: Task): task is ArCategorize {
  return (
    task.responseType === ResponseType.Ar && task.arType === ArType.Categorize
  );
}

export function isMCAlternative(
  answer: MCAlternative | CategorizeAlternative | OrderAlternative
): answer is MCAlternative {
  return 'correct' in answer;
}

export type { Mc, FreeText, Photo, Video, ArOrder, ArCategorize, ArMc };
/**
 * @swagger
 * components:
 *   schemas:
 *     ArType:
 *       enum:
 *         - Order
 *         - Categorize
 *       type: string
 *     ResponseType:
 *       enum:
 *         - Multiple Choice
 *         - Free text
 *         - Photo
 *         - AR
 *       type: string
 *     Task:
 *       anyOf:
 *         - additionalProperties: false
 *           properties:
 *             active:
 *               type: boolean
 *             collections:
 *               items:
 *                - name: uid
 *                  type: string
 *                - name: title
 *                  type: string
 *               type: array
 *             alternatives:
 *               items:
 *                 additionalProperties: false
 *                 properties:
 *                   answerText:
 *                     type: string
 *                   correct:
 *                     type: boolean
 *                   index:
 *                     type: number
 *                   points:
 *                     type: number
 *                   precentage:
 *                     type: number
 *                 required:
 *                   - answerText
 *                   - correct
 *                   - index
 *                   - points
 *                   - precentage
 *                 type: object
 *               type: array
 *             arTemplate:
 *               type: number
 *             arType:
 *               $ref: '#/components/schemas/ArType'
 *             category1:
 *               type: string
 *             category2:
 *               type: string
 *             correct:
 *               type: boolean
 *             created:
 *               type: string
 *             isQuestionScore:
 *               type: boolean
 *             points:
 *               type: number
 *             question:
 *               type: string
 *             questionScore:
 *               type: number
 *             questionURL:
 *               type: string
 *             randomizeAlternatives:
 *               type: boolean
 *             responseType:
 *               $ref: '#/components/schemas/ResponseType'
 *             tags:
 *               items:
 *                 type: string
 *               type: array
 *             title:
 *               type: string
 *             uid:
 *               type: string
 *             updated:
 *               type: string
 *             userFirstName:
 *               type: string
 *             userId:
 *               type: string
 *             userLastName:
 *               type: string
 *             welcomeText:
 *               type: string
 *           required:
 *             - active
 *             - alternatives
 *             - arTemplate
 *             - arType
 *             - category1
 *             - category2
 *             - correct
 *             - created
 *             - isQuestionScore
 *             - points
 *             - question
 *             - questionScore
 *             - questionURL
 *             - randomizeAlternatives
 *             - responseType
 *             - tags
 *             - title
 *             - uid
 *             - updated
 *             - userFirstName
 *             - userId
 *             - userLastName
 *             - welcomeText
 *           type: object
 *         - additionalProperties: false
 *           properties:
 *             active:
 *               type: boolean
 *             arTemplate:
 *               type: number
 *             arType:
 *               $ref: '#/components/schemas/ArType'
 *             category1:
 *               type: string
 *             category2:
 *               type: string
 *             created:
 *               type: string
 *             freeTextAnswer:
 *               type: string
 *             isQuestionScore:
 *               type: boolean
 *             points:
 *               type: number
 *             question:
 *               type: string
 *             questionScore:
 *               type: number
 *             questionURL:
 *               type: string
 *             randomizeAlternatives:
 *               type: boolean
 *             responseType:
 *               $ref: '#/components/schemas/ResponseType'
 *             tags:
 *               items:
 *                 type: string
 *               type: array
 *             title:
 *               type: string
 *             uid:
 *               type: string
 *             updated:
 *               type: string
 *             userFirstName:
 *               type: string
 *             userId:
 *               type: string
 *             userLastName:
 *               type: string
 *             welcomeText:
 *               type: string
 *           required:
 *             - active
 *             - arTemplate
 *             - arType
 *             - category1
 *             - category2
 *             - created
 *             - freeTextAnswer
 *             - isQuestionScore
 *             - points
 *             - question
 *             - questionScore
 *             - questionURL
 *             - randomizeAlternatives
 *             - responseType
 *             - tags
 *             - title
 *             - uid
 *             - updated
 *             - userFirstName
 *             - userId
 *             - userLastName
 *             - welcomeText
 *           type: object
 *         - additionalProperties: false
 *           properties:
 *             active:
 *               type: boolean
 *             arTemplate:
 *               type: number
 *             arType:
 *               $ref: '#/components/schemas/ArType'
 *             category1:
 *               type: string
 *             category2:
 *               type: string
 *             created:
 *               type: string
 *             isQuestionScore:
 *               type: boolean
 *             photoAnswer:
 *               type: string
 *             points:
 *               type: number
 *             question:
 *               type: string
 *             questionScore:
 *               type: number
 *             questionURL:
 *               type: string
 *             randomizeAlternatives:
 *               type: boolean
 *             responseType:
 *               $ref: '#/components/schemas/ResponseType'
 *             tags:
 *               items:
 *                 type: string
 *               type: array
 *             title:
 *               type: string
 *             uid:
 *               type: string
 *             updated:
 *               type: string
 *             userFirstName:
 *               type: string
 *             userId:
 *               type: string
 *             userLastName:
 *               type: string
 *             welcomeText:
 *               type: string
 *           required:
 *             - active
 *             - arTemplate
 *             - arType
 *             - category1
 *             - category2
 *             - created
 *             - isQuestionScore
 *             - photoAnswer
 *             - points
 *             - question
 *             - questionScore
 *             - questionURL
 *             - randomizeAlternatives
 *             - responseType
 *             - tags
 *             - title
 *             - uid
 *             - updated
 *             - userFirstName
 *             - userId
 *             - userLastName
 *             - welcomeText
 *           type: object
 *         - additionalProperties: false
 *           properties:
 *             active:
 *               type: boolean
 *             arTemplate:
 *               type: number
 *             arType:
 *               $ref: '#/components/schemas/ArType'
 *             category1:
 *               type: string
 *             category2:
 *               type: string
 *             correct:
 *               type: boolean
 *             created:
 *               type: string
 *             isQuestionScore:
 *               type: boolean
 *             points:
 *               type: number
 *             question:
 *               type: string
 *             questionScore:
 *               type: number
 *             questionURL:
 *               type: string
 *             randomizeAlternatives:
 *               type: boolean
 *             responseType:
 *               $ref: '#/components/schemas/ResponseType'
 *             tags:
 *               items:
 *                 type: string
 *               type: array
 *             title:
 *               type: string
 *             uid:
 *               type: string
 *             updated:
 *               type: string
 *             userFirstName:
 *               type: string
 *             userId:
 *               type: string
 *             userLastName:
 *               type: string
 *             welcomeText:
 *               type: string
 *           required:
 *             - active
 *             - arTemplate
 *             - arType
 *             - category1
 *             - category2
 *             - correct
 *             - created
 *             - isQuestionScore
 *             - points
 *             - question
 *             - questionScore
 *             - questionURL
 *             - randomizeAlternatives
 *             - responseType
 *             - tags
 *             - title
 *             - uid
 *             - updated
 *             - userFirstName
 *             - userId
 *             - userLastName
 *             - welcomeText
 *           type: object
 *         - additionalProperties: false
 *           properties:
 *             active:
 *               type: boolean
 *             alternatives:
 *               items:
 *                 additionalProperties: false
 *                 properties:
 *                   index:
 *                     type: number
 *                   answerText:
 *                     type: string
 *                 required:
 *                   - index
 *                   - answerText
 *                 type: object
 *               type: array
 *             arTemplate:
 *               type: number
 *             arType:
 *               $ref: '#/components/schemas/ArType'
 *             category1:
 *               type: string
 *             category2:
 *               type: string
 *             correct:
 *               type: boolean
 *             created:
 *               type: string
 *             isQuestionScore:
 *               type: boolean
 *             points:
 *               type: number
 *             question:
 *               type: string
 *             questionScore:
 *               type: number
 *             questionURL:
 *               type: string
 *             randomizeAlternatives:
 *               type: boolean
 *             responseType:
 *               $ref: '#/components/schemas/ResponseType'
 *             tags:
 *               items:
 *                 type: string
 *               type: array
 *             title:
 *               type: string
 *             uid:
 *               type: string
 *             updated:
 *               type: string
 *             userFirstName:
 *               type: string
 *             userId:
 *               type: string
 *             userLastName:
 *               type: string
 *             welcomeText:
 *               type: string
 *           required:
 *             - active
 *             - alternatives
 *             - arTemplate
 *             - arType
 *             - category1
 *             - category2
 *             - correct
 *             - created
 *             - isQuestionScore
 *             - points
 *             - question
 *             - questionScore
 *             - questionURL
 *             - randomizeAlternatives
 *             - responseType
 *             - tags
 *             - title
 *             - uid
 *             - updated
 *             - userFirstName
 *             - userId
 *             - userLastName
 *             - welcomeText
 *           type: object
 */
