import { firestore } from '../types/firebase';

export type Organization = {
  id: string;
  name: string;
  country: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  type: OrganizationType;
  numberId?: string;
  parentId?: string;
  locked?: boolean;
  visibility?: boolean;
  createdAt: firestore.Timestamp;
  updatedAt: firestore.Timestamp;
};

export enum OrganizationType {
  Business = 'business',
  Education = 'education',
  Other = 'other',
  Private = 'private',
}
