import colors from 'styles/colors.module.scss';
import { StyleRules, FormLabelClassKey } from '@material-ui/core';

export const MuiFormLabel: Partial<StyleRules<FormLabelClassKey, {}>> = {
  root: {
    fontWeight: 700,
    color: colors.black,
    lineHeight: 1.25,
  },
};
