import { StyleRules, MenuClassKey } from '@material-ui/core';
import colors from 'styles/colors.module.scss';

export const MuiMenu: Partial<StyleRules<MenuClassKey, {}>> = {
  paper: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors.primary,
  },
  list: {
    padding: 0,
  },
};
