import { firestore } from '../types/firebase';

export interface OrganizationMember {
  userId: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  role: OrganizationRole;
  status: MembershipStatus;
  appliedGroup?: { groupId: string; groupName: string };
  createdAt: firestore.Timestamp;
  updatedAt: firestore.Timestamp;
}

export enum OrganizationRole {
  AccountOwner = 'accountOwner',
  Admin = 'admin',
  Gamemaster = 'gamemaster',
  Player = 'player',
}

export enum MembershipStatus {
  Active = 'active',
  Rejected = 'rejected',
  Deactivated = 'deactivated',
  Applied = 'applied',
  Invited = 'invited',
  LeaveRequest = 'leaveRequest',
}
