import React, { ReactNode, useEffect, useState } from 'react';
import { createCtx } from 'helpers/createContext';
import { ConfigService } from 'services/Config.service';
import { Config } from '@shared/models/Config';
import configDefaults from 'configDefaults.json';

const [useConfig, ConfigDataProvider] = createCtx<Config>();

type Props = {
  children: ReactNode;
};

const ConfigProvider = ({ children }: Props) => {
  const [config, setConfig] = useState<Config>(configDefaults);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const newConfig = await ConfigService.getAll();
        setConfig(newConfig);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConfig();
  }, []);

  return <ConfigDataProvider value={config}>{children}</ConfigDataProvider>;
};

export { useConfig, ConfigProvider };
