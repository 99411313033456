import { StyleRules, PaperClassKey } from '@material-ui/core';
import colors from '../colors.module.scss';

export const MuiPaper: Partial<StyleRules<PaperClassKey, {}>> = {
  outlined: {
    border: `2px solid ${colors.primary400}`,
    borderRadius: 8,
    backgroundColor: colors.background,
  },
};
