import { StyleRules, TableRowClassKey } from '@material-ui/core';
import colors from 'styles/colors.module.scss';

export const MuiTableRow: Partial<StyleRules<TableRowClassKey, {}>> = {
  root: {
    backgroundColor: colors.white,
  },
  head: {
    backgroundColor: colors.primary300,
  },
  footer: {
    backgroundColor: colors.primary300,
  },
};
