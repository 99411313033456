import React from 'react';
import { useActivateNotification } from 'contexts/ToasterNotificationContext';
import ConfirmationCollapse from 'components/shared/ConfirmationCollapse';

export const Alerts = () => {
  const { isNotificationOpen, notificationMessage, modeSuccess } =
    useActivateNotification();

  return (
    <ConfirmationCollapse
      isSubmitted={isNotificationOpen}
      message={notificationMessage}
      create={modeSuccess}
    />
  );
};
