import { firestore } from '../types/firebase';

export interface FriendInvite {
  uid: string;
  status: InviteStatus;
  createdAt: firestore.Timestamp;
  fromUid: string;
  toUid: string;
  fromEmail?: string;
  toEmail?: string;
  fromFirstName?: string;
  toFirstName?: string;
  fromLastName?: string;
  toLastName?: string;
  fromUsername?: string;
  toUsername?: string;
}

export enum InviteStatus {
  Sent = 'sent',
  Accepted = 'accepted',
  Rejected = 'rejected',
}
