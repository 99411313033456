import { useDocumentData } from 'react-firebase-hooks/firestore';

import { User } from '@shared/models';
import { useAuth } from 'contexts';
import { db } from '../../firebase';

export const useUserInfo = () => {
  const { currentUser } = useAuth();

  const query = db.collection('users').doc(currentUser?.uid || ' ');
  const [userProfile, loadingUserProfile] = useDocumentData<User>(query, {
    idField: 'uid',
  });

  return { userProfile, loadingUserProfile };
};
