import { StyleRules, ChipClassKey } from '@material-ui/core';
import colors from '../colors.module.scss';
export const MuiChip: Partial<StyleRules<ChipClassKey, {}>> = {
  sizeSmall: {
    minWidth: 49.09,
    height: 19,
  },
  colorPrimary: {
    color: colors.white,
    backgroundColor: colors.quaternary,
  },
  colorSecondary: {
    color: colors.quaternary,
    backgroundColor: colors.primary300,
  },
  outlinedPrimary: {
    color: colors.primary,
  },
  outlinedSecondary: {
    color: colors.secondary,
  },
  labelSmall: {
    width: '100%',
    height: 15,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8.73,
    paddingRight: 8.73,
    fontSize: 12,
    lineHeight: 1,
    fontWeight: 600,
    textAlign: 'center',
  },
};
