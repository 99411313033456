import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ReactComponent as RectangleLogo } from 'assets/icons/wittario-icon.svg';
import { ReactComponent as ActionIcon } from 'assets/icons/arrow-right.svg';
import { Container } from 'components/Container';
import { config } from '../../config/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: 600,
      height: '100%',
      margin: '0 auto',
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    rectangleLogo: {
      position: 'absolute',
      top: 10,
      right: 20,
      width: 140,
      height: 140,
    },
    actionIcon: {
      marginRight: '4px',
      color: '#D6D6E4',
    },
    title: {
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: 1.2,
      marginBottom: '12px',
    },
    text: {
      lineHeight: 1.5,
    },
    button: {
      marginTop: '35px',
      color: '#D6D6E4',
      backgroundColor: '#6264a7',
    },
  })
);

const redirect = () => {
  window.location.href = config.wittarioWeb;
};

const MovedPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box mt={4}>
        <Container
          className={classes.root}
          logo={<RectangleLogo className={classes.rectangleLogo} />}
        >
          <Typography className={classes.title} variant="h6">
            {t('moved.header')}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {t('moved.text')}
          </Typography>
          <Button onClick={redirect} className={classes.button} color="primary">
            <ActionIcon className={classes.actionIcon} />
            {t('moved.action')}
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default MovedPage;
