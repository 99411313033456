import { useCollectionData } from 'react-firebase-hooks/firestore';

import { Notification } from '@shared/models';
import NotificationService from 'services/NotificationService';
import { useAuth } from 'contexts';

export const useUserNotifications = (limit?: number) => {
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  const [notifications] = useCollectionData<Notification>(
    userId ? NotificationService.getAllForUserQuery(userId, limit) : undefined,
    {
      idField: 'uid',
    }
  );

  return { notifications: notifications || [] };
};
