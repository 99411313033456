import { createStore as createLittleStore } from 'little-state-machine';
import {
  registerDefaultValues,
  gameDefaultValues,
  collectionDefaultValues,
} from 'stores/defaults';

const createStore = () =>
  createLittleStore(
    {
      register: registerDefaultValues,
      game: gameDefaultValues,
      collection: collectionDefaultValues,
    },
    {}
  );

export { registerDefaultValues, createStore };
