import { firestore } from '../types/firebase';
import { Friend } from './Friend';
import { Spot } from './Route.model';
import { Task } from './Task';

export enum WayToPlay {
  Now = 1,
  Schedule = 2,
}

export enum GameStatus {
  Draft = 0,
  Created = 1,
  Started = 2,
  Playing = 3,
  Paused = 4,
  Completed = 5,
  TeamSwitching = 6,
  Evaluated = 7,
}

export enum GameType {
  Team = 1,
  TeamWithCommunication = 2,
  Solo = 3,
  Quick = 4,
  Business = 5,
}

export enum GameVariant {
  LocationBasedGame = 1,
  DistanceBasedGame = 2,
  QrGame = 3,
  Stationary = 4,
}
interface Game {
  uid: string;
  status: GameStatus.Draft | GameStatus.Created | GameStatus.Started;
  title: string;
  active: boolean;
  gameType: GameType;
  gameVariant: GameVariant;
  gamePin: number;
  allowedTime: number | string;
  maxPlayersPerTeam?: number;
  maxTeamsCount?: number;

  created: firestore.Timestamp;
  completedAt?: firestore.Timestamp;

  route: string;
  routeSpotsCount: number;
  routeTitle: string;
  spots: GameSpot[];
  distanceOptions?: DistanceOptions;
  branding?: GameBranding;
  imageUrl?: string;
  id: string;
  allowAnonymousPlayers?: boolean;
  monitored?: boolean;
  writebackPartnerId?: string;
  invitedUsers?: InvitedFriend[];
  isAutoEvaluating: boolean;

  userId: string;
  userFirstName: string;
  userLastName: string;
  avatarUrl?: string;

  lastStartAt?: firestore.Timestamp;
  playsCount?: number;
  keepTasksOrder?: boolean;
  stationary?: boolean;

  orgId: string;
}

interface InvitedFriend extends Friend {
  invitedAt?: firestore.Timestamp;
}

type GameSpot = RouteSpot | DistanceSpot | QRSpot;

interface DistanceSpot {
  uid: number;
  task: string;
  distance: number;
  data: Task;
}

interface RouteSpot extends Spot {
  data: Task;
}

interface QRSpot {
  uid: number;
  data: Task;
  locationDescription?: QRLocationDescription;
  qrUrl?: string;
}

interface DistanceOptions {
  manualDistance: boolean;
  distance: number;
}

interface QRLocationDescription {
  description: string;
  imageUrl: string;
}

type GameBranding = {
  image: string;
  description: string | null;
};

export type { Game, DistanceSpot, GameSpot, RouteSpot, QRSpot };
