import { StonlyEvents } from '../../consts/stonlyProperties';
import { firestore } from '../types/firebase';
import { GameType, GameStatus } from './GameTemplate';
import { Friend } from './Friend';
import { OrganisationType } from './Organisation';
import { OrganizationRole } from './OrganizationMember';
import { Organization, OrganizationType } from './Organization';
import { TeamMemberRole } from './Team';

interface User {
  uid: string;
  eMail: string;
  firstName: string;
  lastName: string;
  orgCode: string;
  orgType?: OrganisationType;
  role: Role | Role[];
  groups: UserGroup[];
  userCode?: number;
  username?: string;
  availGames?: AvailGame[];
  points?: number;
  receiveNewsletter: boolean;
  createdAt: firestore.Timestamp;
  updatedAt: firestore.Timestamp;
  friends?: Friend[];
  totalDistance?: number;
  tasksAnswered?: number;
  lastShownWhatsNewVersion?: number;
  deleted?: boolean;
  language?: string;
  stonlyProperties?: StonlyProperties;
  currentOrganizationId?: string;
  organizations: UserOrganization[];
  invitations?: UserInvitations[];
  applications?: UserApplications[];
  teams?: UserTeam[];
  superadmin?: boolean;
  superadminActiveOrganization?: UserOrganization;
  superadminCachedOrganizations?: SuperadminCachedOrganizations;
}

interface SuperadminCachedOrganizations {
  updatedAt: firestore.Timestamp;
  organizations: Pick<Organization, 'id' | 'name' | 'type'>[];
}

export type UserInvitations = InvitationToOrganization &
  InvitationToGroup &
  InvitationToTeam;

export type UserApplications = ApplicationToOrganization &
  ApplicationToOrganizationUnit;

interface Invitation {
  title: string;
  subtitle?: string;
  type: InvitationTypes;
}

export interface InvitationToOrganization extends Invitation {
  organizationId: string;
  memberId: string;
}

export interface InvitationToGroup extends Invitation {
  organizationId: string;
  groupId: string;
}

export interface InvitationToTeam extends Invitation {
  teamId: string;
}

export interface Application {
  type: ApplicationTypes;
}
export interface ApplicationToOrganization extends Application {
  organizationId: string;
  title: string;
}

export interface ApplicationToOrganizationUnit
  extends ApplicationToOrganization {
  groupId: string;
  subtitle: string;
}

export interface UserOrganization {
  id: string;
  name: string;
  role: OrganizationRole;
  type: OrganizationType;
}

export enum InvitationTypes {
  organizationMember = 'organizationMember',
  organizationGroup = 'organizationGroup',
  team = 'team',
}

export enum ApplicationTypes {
  Organization = 'organization',
  Unit = 'unit',
}

interface UserGroup {
  title: string;
  uid: string;
}

interface UserTeam {
  role: TeamMemberRole;
  title: string;
  uid: string;
}

export interface StonlyProperties extends Record<StonlyEvents, 'string'> {
  language?: string;
  role?: Role;
  orgType?: OrganisationType;
}

type AvailGame = {
  gamePin: number;
  gameType: GameType;
  gameUID: string;
  status: GameStatus;
  title: string;
  totalScore?: number;
};

enum Role {
  Admin = 'Admin',
  Teacher = 'Teacher',
  Student = 'Student',
  Gamemaster = 'Gamemaster',
  Player = 'Player',
  AssetsAdmin = 'AssetsAdmin',
  InternalAdmin = 'InternalAdmin',
}

export type { User, UserGroup, UserTeam, AvailGame };
export { Role };
/**
 * @swagger
 * components:
 *   schemas:
 *     Group:
 *       additionalProperties: false
 *       properties:
 *         title:
 *           type: string
 *         uid:
 *           type: string
 *       required:
 *         - title
 *         - uid
 *       type: object
 *     User:
 *       additionalProperties: false
 *       properties:
 *         eMail:
 *           type: string
 *         firstName:
 *           type: string
 *         groups:
 *           items:
 *             $ref: '#/components/schemas/Group'
 *           type: array
 *         lastName:
 *           type: string
 *         orgCode:
 *           type: string
 *         role:
 *            type: string
 *            enum:
 *              - Admin
 *              - Teacher
 *              - Student
 *              - Gamemaster
 *            example: Teacher
 *         uid:
 *           type: string
 *       required:
 *         - uid
 *         - eMail
 *         - firstName
 *         - lastName
 *         - orgCode
 *         - role
 *         - groups
 *       type: object
 */
