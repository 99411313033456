import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/app';

import { useActivateNotification } from 'contexts/ToasterNotificationContext';
import { useConfig } from 'contexts/Config/Config';
import { updateOnUserState, setOffline } from 'services/UserPresence.service';
import { createCtx } from 'helpers/createContext';
import { UserRole } from 'consts/UserRole';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { auth, getGoogleProvider, getMicrosoftProvider } from '../../firebase';
import { config } from '../../config';
import { AuthContextType } from '.';

const verifyAccount = config.verifyAccount.toString();

const [useAuth, AuthDataProvider] = createCtx<AuthContextType>();

const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const [currentUser, isLoading] = useAuthState(auth);
  const { loading: loadingAuthClaims, claims: authClaims } = useAuthClaims();
  const [role, setRole] = useState<UserRole[]>();
  const [redirectionPath, setRedirectionPath] = useState('');
  const { features: onlinePresence } = useConfig();

  const userId = currentUser?.uid;
  const isRoleLoading = !!currentUser && loadingAuthClaims;
  const { activateNotification } = useActivateNotification();
  const { t } = useTranslation();

  const signOut = useCallback(() => {
    if (!userId) {
      return;
    }

    Sentry.configureScope((scope) => scope.setUser(null));

    setRole(undefined);

    if (onlinePresence) {
      setOffline(userId);
    }

    return auth.signOut();
  }, [userId, onlinePresence]);

  const signIn = useCallback(
    async (email: string, password: string, rememberMe?: boolean) => {
      if (rememberMe !== undefined) {
        await auth.setPersistence(
          rememberMe
            ? firebase.auth.Auth.Persistence.LOCAL
            : firebase.auth.Auth.Persistence.SESSION
        );
      }

      return auth.signInWithEmailAndPassword(email, password);
    },
    []
  );
  const changeUserPassword = async (password: string) => {
    if (!currentUser) {
      return;
    }

    try {
      await currentUser.updatePassword(password);
      activateNotification({
        closeTime: 3500,
        message: t('common.password-has-been-changed-successfully'),
      });
    } catch (error) {
      activateNotification({
        closeTime: 3500,
        message: error.message,
        isSuccess: false,
      });
    }
  };

  const verifyEmail = (oobCode: string) => auth.applyActionCode(oobCode);

  const reauthenticate = async (currentPassword: string) => {
    const cred = firebase.auth.EmailAuthProvider.credential(
      currentUser?.email || '',
      currentPassword
    );

    return await currentUser?.reauthenticateWithCredential(cred);
  };

  const forgotPassword = async (email: string, notification = true) => {
    try {
      await auth.sendPasswordResetEmail(email, {
        url: `https://${config.firebase.authDomain}`,
      });
      notification &&
        activateNotification({
          closeTime: 3500,
          message: t('common.forgot-password-confirmation'),
        });
    } catch (error) {
      notification &&
        activateNotification({
          closeTime: 3500,
          message: error.message,
          isSuccess: false,
        });
    }
  };

  const resetPassword = (oobCode: string, newPassword: string) =>
    auth.confirmPasswordReset(oobCode, newPassword);

  useEffect(() => {
    const loadUser = async () => {
      if (isLoading) {
        return;
      }

      if (
        !currentUser ||
        (!currentUser.emailVerified && verifyAccount === 'true')
      ) {
        return signOut();
      }

      Sentry.setUser({
        id: currentUser.uid,
        email: currentUser.email || '',
        name: currentUser.displayName,
      });

      const tokenResult = await currentUser.getIdTokenResult();
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(tokenResult.token);
      }
      setRole(authClaims?.role);
      if (onlinePresence) {
        updateOnUserState(currentUser.uid);
      }
    };

    loadUser();
  }, [currentUser, isLoading, signOut, onlinePresence, authClaims?.role]);

  return (
    <AuthDataProvider
      value={{
        currentUser: currentUser ? { ...currentUser, role } : undefined,
        loading: isLoading || isRoleLoading,
        redirectionPath,
        signOut,
        signIn,
        signInGoogle,
        signInMicrosoft,
        changeUserPassword,
        forgotPassword,
        resetPassword,
        verifyEmail,
        setRedirectionPath,
        reauthenticate,
      }}
    >
      {children}
    </AuthDataProvider>
  );
};

const signInSocial = (
  loginProvider: firebase.auth.GoogleAuthProvider_Instance
) => auth.signInWithPopup(loginProvider);

const signInGoogle = () => signInSocial(getGoogleProvider());
const signInMicrosoft = () => signInSocial(getMicrosoftProvider());

export { AuthProvider, useAuth };
