import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { auth } from '../../firebase';

export const useAuthClaims = () => {
  const [claims, setClaims] = useState<Claims>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      const tokenData = await user?.getIdTokenResult();
      const newClaims = tokenData?.claims;

      setClaims(newClaims);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return { claims, loading };
};

type Claims = firebase.auth.IdTokenResult['claims'];
