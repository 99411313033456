import React, { FC } from 'react';
import { DialogProvider } from 'muibox';
import { AuthProvider } from 'contexts/Auth/Auth';
import { ThemeProvider } from '@material-ui/styles';
import { UserProvider } from 'contexts/User/User';
import { ToasterNotificationProvider } from 'contexts/ToasterNotificationContext';
import { ConfigProvider } from 'contexts/Config/Config';
import { theme } from '../../styles/theme';

export const GlobalContextsWrapper: FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <DialogProvider>
        <ToasterNotificationProvider>
          <ConfigProvider>
            <AuthProvider>
              <UserProvider>{children}</UserProvider>
            </AuthProvider>
          </ConfigProvider>
        </ToasterNotificationProvider>
      </DialogProvider>
    </ThemeProvider>
  );
};
