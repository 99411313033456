//import React, { Suspense, lazy } from 'react';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { useAuth } from 'contexts';
import { Loading } from 'components/Loading/Loading';
import { useUserInfo } from 'hooks/useUserInfo/useUserInfo';
import { useUser } from 'contexts/User/User';
import MovedPage from 'pages/moved';

// const AuthenticatedRoutes = lazy(() => import('./AuthenticatedRoutes'));
// const UnauthenticatedRoutes = lazy(() => import('./UnauthenticatedRoutes'));

export const AppRouter = () => {
  //const { loading, currentUser } = useAuth();
  const { loading } = useAuth();
  const { loadingUserProfile } = useUserInfo();
  const { loadingUserProfile: loadingProfileFromUser } = useUser();

  if (loading || loadingUserProfile || loadingProfileFromUser) {
    return <Loading overlay />;
  }
  //const isFullyLoggedIn = !!currentUser?.role?.length;
  // const RoutesComponent = !isFullyLoggedIn
  //   ? UnauthenticatedRoutes
  //   : AuthenticatedRoutes;

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Suspense fallback={<Loading overlay />}>
        {/* <RoutesComponent /> */}
        <Routes>
          <Route path="*" element={<MovedPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
