import { firestore } from '../types/firebase';
import { User } from './User';

export enum OrganisationType {
  School = 'School',
  Business = 'Business',
}
export interface Organisation {
  address1: string;
  address2: string;
  admin: string;
  country: string;
  orgCode: string;
  phone: string;
  stateProvince?: string;
  type: OrganisationType;
  zipCode: string;
  availableAssets?: string[];
  allowBusinessGame?: boolean;
  allowCustomArTask?: boolean;
  members?: User[];
  childs?: [];
  createdAt: firestore.Timestamp;
  brandImage?: string;
}

export type OrganisationData = {
  orgName: string;
  orgAddress1: string;
  orgZipCode: string;
  orgCountry: string;
  orgTel?: string;
  orgAddress2?: string;
  orgCity: string;
  orgStateProvince?: string;
  orgType: OrganisationType;
};
