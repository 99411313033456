import { StyleRules, TextFieldClassKey } from '@material-ui/core';
export const MuiTextField: Partial<StyleRules<TextFieldClassKey, {}>> = {
  root: {
    '& p': {
      marginLeft: 0,
    },
    transition: 'all .3s ease-in-out',
    animationDuration: '30ms',
  },
};
