import { OrganizationRole } from '@shared/models/OrganizationMember';
import { Role as UserRole } from '@shared/models/User';

const GAME_ADMIN_ROLES = [
  UserRole.Admin,
  UserRole.Gamemaster,
  UserRole.Teacher,
];

const GAME_ADMIN_AFFILIATED_ROLES = [
  OrganizationRole.Admin,
  OrganizationRole.Gamemaster,
  OrganizationRole.AccountOwner,
];

const ORGANIZATION_ADMIN_AFFILIATED_ROLES = [
  OrganizationRole.Admin,
  OrganizationRole.AccountOwner,
];

const PLAYER_ROLES = [UserRole.Player, UserRole.Student];

const PLAYER_AFFILIATED_ROLES = [OrganizationRole.Player];

export {
  UserRole,
  GAME_ADMIN_ROLES,
  PLAYER_ROLES,
  GAME_ADMIN_AFFILIATED_ROLES,
  ORGANIZATION_ADMIN_AFFILIATED_ROLES,
  PLAYER_AFFILIATED_ROLES,
};
