import React, { useState, createContext, useContext, useCallback } from 'react';

type ActivateNotificationParams = {
  closeTime?: number;
  message: string;
  isSuccess?: boolean;
};

type ContextType = {
  isNotificationOpen: boolean;
  setNotificationOpen: (open: boolean) => void;
  notificationMessage: string;
  setNotificationMessage(message: string): void;
  modeSuccess: boolean;
  setModeSuccess(isSuccess: boolean): void;
  activateNotification(params: ActivateNotificationParams): void;
};

const DEFAULT_CLOSE_TIME_IN_MS = 3000;

const ToasterNotificationContext = createContext({} as ContextType);

const ToasterNotificationProvider: React.FC = ({ children }) => {
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(
    'Sample modal message'
  );
  const [modeSuccess, setModeSuccess] = useState(true);

  const activateNotification = useCallback(
    ({
      closeTime = DEFAULT_CLOSE_TIME_IN_MS,
      message,
      isSuccess = true,
    }: ActivateNotificationParams) => {
      return new Promise((resolve, reject) => {
        try {
          setNotificationOpen(true);
          setNotificationMessage(message);
          setModeSuccess(isSuccess);

          setTimeout(() => {
            setNotificationOpen(false);
            resolve(true);
          }, closeTime);
        } catch (error) {
          reject(error);
        }
      });
    },
    []
  );

  return (
    <ToasterNotificationContext.Provider
      value={{
        isNotificationOpen,
        setNotificationOpen,
        notificationMessage,
        setNotificationMessage,
        modeSuccess,
        setModeSuccess,
        activateNotification,
      }}
    >
      {children}
    </ToasterNotificationContext.Provider>
  );
};

const useActivateNotification = () => {
  const context = useContext(ToasterNotificationContext);
  if (context === undefined) {
    throw new Error(`useContext must be used within a Provider`);
  }

  return context;
};

export {
  ToasterNotificationContext,
  ToasterNotificationProvider,
  useActivateNotification,
};
