import { checkIfUserHasRoleInOrganization } from '@shared/helpers/checkIfUserHasRoleInOrganization';
import { OrganizationRole } from '@shared/models/OrganizationMember';

export const canAccessMarket = (
  userRoles: OrganizationRole | OrganizationRole[] = [],
  userOrgCode?: string,
  disabledForGamemastersOrgs?: string[]
) => {
  if (!disabledForGamemastersOrgs) {
    return false;
  }

  if (
    checkIfUserHasRoleInOrganization(userRoles, [
      OrganizationRole.Admin,
      OrganizationRole.AccountOwner,
    ]) ||
    !userOrgCode
  ) {
    return true;
  }

  return (
    checkIfUserHasRoleInOrganization(userRoles, [
      OrganizationRole.Gamemaster,
    ]) && !disabledForGamemastersOrgs.includes(userOrgCode)
  );
};
