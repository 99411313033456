import React from 'react';
import { makeStyles, StepIcon, StepIconProps } from '@material-ui/core';
import { ReactComponent as CheckedIcon } from 'assets/icons/checkmark.svg';
import colors from 'styles/colors.module.scss';

const useBetterStepIconStyles = makeStyles({
  root: {
    width: 44,
    height: 44,
    borderRadius: 44,
    backgroundColor: colors.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function BetterStepIcon(props: StepIconProps) {
  const classes = useBetterStepIconStyles();
  const { completed } = props;

  return (
    <>
      {completed ? (
        <div className={classes.root}>
          <StepIcon {...props} icon={<CheckedIcon />} />
        </div>
      ) : (
        <StepIcon {...props} />
      )}
    </>
  );
}
