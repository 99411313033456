import { StyleRules, AvatarClassKey } from '@material-ui/core';
import colors from '../colors.module.scss';

export const MuiAvatar: Partial<StyleRules<AvatarClassKey, {}>> = {
  colorDefault: {
    color: colors.primary,
    backgroundColor: colors.primary400,
    fontWeight: 700,
    fontSize: 16,
  },
};
