import { StyleRules, TooltipClassKey } from '@material-ui/core';
import colors from 'styles/colors.module.scss';

const border = `1px solid ${colors.primary}`;

export const MuiTooltip: Partial<StyleRules<TooltipClassKey, {}>> = {
  tooltip: {
    backgroundColor: 'white',
    border,
    color: 'black',
  },
  arrow: {
    color: 'white',

    '&::before': {
      border,
    },
  },
  popperArrow: {
    '&[x-placement*="right"] $arrow': {
      marginLeft: '-0.69em',
    },
  },
};
