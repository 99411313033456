import { ButtonProps, StyleRules, ButtonClassKey } from '@material-ui/core';
import colors from '../colors.module.scss';

const getColor =
  () =>
  ({ color, variant }: ButtonProps) => {
    // @ts-expect-error
    if (color !== 'quaternary') {
      return;
    }

    if (variant === 'contained') {
      return colors.white;
    }

    return colors.quaternary;
  };

const getBgColor = () => (props: ButtonProps) =>
  // @ts-expect-error
  props.color === 'quaternary' && props.variant === 'contained'
    ? colors.quaternary
    : undefined;
const getBgColorHover = () => (props: ButtonProps) =>
  // @ts-expect-error
  props.color === 'quaternary' && props.variant === 'contained'
    ? colors.quaternaryLight
    : undefined;
const getBorderColor = () => (props: ButtonProps) =>
  // @ts-expect-error
  props.color === 'quaternary' ? colors.quaternaryLight : undefined;

export const MuiButton: Partial<StyleRules<ButtonClassKey, {}>> = {
  root: {
    padding: `${10}px ${50}px`,
    borderRadius: 4,
    color: getColor(),
    backgroundColor: getBgColor(),
    '&:hover': {
      backgroundColor: getBgColorHover(),
    },
    borderColor: getBorderColor(),
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: colors.primaryLight,
    },
  },
  outlined: {
    padding: `${12}px ${52}px`,
    '&:hover': {
      backgroundColor: colors.quaternaryHover,
    },
  },
  contained: {
    padding: `${12}px ${52}px`,
  },
};
