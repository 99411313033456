import { Optional } from 'utility-types';
import { GameVariant, RouteSpot, Task } from '@shared/models';

export interface DistanceTaskError {
  index: number;
  distance?: string;
}
export interface DistanceErrors {
  distance: string;
  tasksCount: string;
  minDistanceBetweenTasks: string;
  selectedTasks: DistanceTaskError[];
}

export interface SelectedTask {
  data: Task;
  distance: number;
}

export const defaultErrors: DistanceErrors = {
  distance: '',
  tasksCount: '',
  minDistanceBetweenTasks: '',
  selectedTasks: [],
};

export interface DistanceValues {
  errors: DistanceErrors;
  tasks: SelectedTask[];
  manualDistance: boolean;
  totalDistance: number;
  isValid: boolean;
}

export const distanceDefaultValues: DistanceValues = {
  errors: defaultErrors,
  tasks: [],
  manualDistance: false,
  totalDistance: 0,
  isValid: false,
};

export type NewGameRouteSpot = Optional<
  RouteSpot,
  'data' | 'lat' | 'long' | 'location'
>;
export interface RouteValues {
  spots: NewGameRouteSpot[];
  isValid: boolean;
}

export const routeDefaultValues: RouteValues = {
  spots: [],
  isValid: false,
};

export interface QrTaskHint {
  image?: File;
  imageUrl?: string;
  text?: string;
}

export interface QrTask {
  data: Task;
  hint?: QrTaskHint;
}

interface QrValues {
  tasks: QrTask[];
  taskForHintModalIndex?: number;
  keepTasksOrder: boolean;
  isValid: boolean;
  stationary: boolean;
}

export const qrDefaultValues: QrValues = {
  tasks: [],
  taskForHintModalIndex: undefined,
  keepTasksOrder: false,
  isValid: false,
  stationary: false,
};

const gameDefaultValues = {
  title: '',
  orgId: '',
  gameVariant: GameVariant.LocationBasedGame,
  distance: distanceDefaultValues,
  route: routeDefaultValues,
  qr: qrDefaultValues,
  editedTaskIndex: undefined as number | undefined,
  activeStep: 0,
  savedGameUid: undefined as string | undefined,
};

export { gameDefaultValues };
