import { MultiBatch } from 'firestore-multi-batch';
import { Notification } from '@shared/models';
import { db, TIMESTAMP } from '../firebase';

const collectionRef = db.collection('notifications');
const DEFAULT_NOTIFICATIONS_LIMIT = 5;

const NotificationService = {
  createNotification,
  getAllForUserQuery,
  update,
  readUnreadNotifications,
  markAllAsRead,
};

async function createNotification(notificationData: Omit<Notification, 'uid'>) {
  await collectionRef.add({
    ...notificationData,
    created: TIMESTAMP,
    deleted: false,
    read: false,
  });
}

function getAllForUserQuery(
  userId: string,
  limit: number = DEFAULT_NOTIFICATIONS_LIMIT
) {
  return db
    .collection('notifications')
    .where('userId', '==', userId)
    .where('deleted', '==', false)
    .orderBy('created')
    .limitToLast(limit);
}

function markAllAsRead(notifications: Notification[]) {
  const batch = new MultiBatch(db as unknown as FirebaseFirestore.Firestore);

  notifications.forEach((notification) => {
    batch.update(
      collectionRef.doc(
        notification.uid
      ) as unknown as FirebaseFirestore.DocumentReference,
      { read: true }
    );
  });

  return batch.commit();
}

async function readUnreadNotifications(notifications: Notification[] = []) {
  const unreadNotifications = notifications.filter(
    (notification) => !notification.read
  );

  const updates = unreadNotifications.map((unread) =>
    update({ uid: unread.uid, data: { read: true } })
  );

  return Promise.all(updates);
}

async function update({
  uid,
  data,
}: {
  uid: string;
  data: Partial<Notification>;
}) {
  return db.doc(`notifications/${uid}`).update(data);
}

Object.freeze(NotificationService);
export default NotificationService;
