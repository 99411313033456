import { RTDB_TIMESTAMP, rtDb } from '../firebase';

const isOfflineForDatabase = {
  state: 'offline',
  last_changed: RTDB_TIMESTAMP,
};

const isOnlineForDatabase = {
  state: 'online',
  last_changed: RTDB_TIMESTAMP,
};

const updateOnUserState = (userId: string) => {
  const userStatusDatabaseRef = rtDb.ref('/status/' + userId);

  return rtDb.ref('.info/connected').on('value', (snapshot) => {
    const isCurrentlyConnected = snapshot.val();
    if (!isCurrentlyConnected) {
      return;
    }

    userStatusDatabaseRef
      .onDisconnect()
      .set(isOfflineForDatabase)
      .then(() => {
        // The promise returned from .onDisconnect().set() will
        // resolve as soon as the server acknowledges the onDisconnect()
        // request, NOT once we've actually disconnected:
        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

        // We can now safely set ourselves as 'online' knowing that the
        // server will mark us as offline once we lose connection.
        userStatusDatabaseRef.set(isOnlineForDatabase);
      });
  });
};

const setOffline = (userId: string) => {
  const userStatusDatabaseRef = rtDb.ref('/status/' + userId);
  userStatusDatabaseRef.set(isOfflineForDatabase);
};

export { updateOnUserState, setOffline };
