import { User } from '@shared/models';
import { StonlyEvents } from 'consts/stonlyProperties';

const StonlyService = {
  identify: async (user?: User) => {
    if (!user || !window.stonlyTrack) return;

    const orgType = user.orgType ? { customer: user.orgType } : {};
    const stonlyKeys = {
      role: user.role,
      language: user.language,
      ...orgType,
      ...user.stonlyProperties,
    };
    if (window.STONLY_WID) {
      await window.stonlyTrack('identify', user.uid, stonlyKeys);
    }
  },
  sendEvent: async (event: StonlyEvents) => {
    if (!window.stonlyTrack) return;
    await window.stonlyTrack('track', event);
  },
};

Object.freeze(StonlyService);
export default StonlyService;
