import { firestore } from '../types/firebase';

export interface Asset {
  unityLabel: string;
  spawnMethod: SpawnMethod;
  previewImage?: string;
  previewModel?: string;
  title?: string;
  createdAt: firestore.Timestamp;
  updatedAt: firestore.Timestamp;
}

export enum SpawnMethod {
  Grounded = 'grounded',
  InAir = 'inAir',
}

export const spawnMethodArray = [
  SpawnMethod.Grounded,
  SpawnMethod.InAir,
] as const;
