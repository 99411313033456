import React from 'react';
import { makeStyles, Paper, PaperProps } from '@material-ui/core';
import { ReactComponent as WittarioLogo } from 'assets/horisontalLogo 1.svg';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    width: '100%',
    margin: 'auto',
    paddingTop: spacing(2),
    paddingBottom: spacing(4),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      width: spacing(70),
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
  },
  logo: {
    marginBottom: 27,
  },
  content: {
    width: '100%',
  },
}));

type Logo = React.ReactNode | undefined;

interface Props extends PaperProps {
  logo?: Logo;
  children: React.ReactNode;
}

export const Container = ({ logo, children, ...paperProps }: Props) => {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.root }} elevation={0} {...paperProps}>
      {logo ? logo : <WittarioLogo className={classes.logo} />}
      <div className={classes.content}>{children}</div>
    </Paper>
  );
};
