import { StyleRules, StepConnectorClasskey } from '@material-ui/core';
import colors from '../colors.module.scss';

export const MuiStepConnector: Partial<StyleRules<StepConnectorClasskey, {}>> =
  {
    lineHorizontal: {
      borderTopWidth: 2,
    },
    completed: {
      '& $line': {
        borderColor: colors.primary,
      },
    },
    active: {
      '& $line': {
        borderColor: colors.primary,
      },
    },
    alternativeLabel: {
      top: 44 / 2,
      left: `calc(-50% + ${22}px)`,
      right: `calc(50% + ${22}px)`,
    },
  };
