import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { checkIfUserHasRoleInOrganization } from '@shared/helpers';
import {
  User,
  Notification,
  UserOrganization,
  OrganizationRole,
} from '@shared/models';
import { ORGANIZATION_ADMIN_AFFILIATED_ROLES } from 'consts/UserRole';
import { useConfig } from 'contexts/Config/Config';
import { canAccessMarket } from 'helpers/canAccessMarket';
import { createCtx } from 'helpers/createContext';
import { getFromLocalStorage } from 'helpers/getFromLocalStorage';
import { setItemToLocalStorage } from 'helpers/setItemToLocalStorage';
import { useUserNotifications } from 'hooks/useNotifications/useNotifications';
import { useUserInfo } from 'hooks/useUserInfo/useUserInfo';
import StonlyService from 'services/StonlyService';

const [useUser, UserDataProvider] = createCtx<ContextType>();

const UserProvider = ({ children }: { children?: ReactNode }) => {
  const { i18n } = useTranslation();
  const { notifications } = useUserNotifications();
  const initialIsAffiliationModalOpen = getFromLocalStorage(
    'user',
    'isAffiliationModalOpen'
  );
  const [isAffiliationModalOpen, setIsAffiliationModalOpen] =
    useState<boolean>(false);

  const { userProfile, loadingUserProfile } = useUserInfo();
  const currentOrganizationId = userProfile?.currentOrganizationId;
  const activeOrganization =
    userProfile?.superadmin && userProfile.superadminActiveOrganization
      ? userProfile.superadminActiveOrganization
      : userProfile?.organizations?.find(
          ({ id }) => id === currentOrganizationId
        );
  const isActiveOrganizationAccountOwner =
    activeOrganization?.role === OrganizationRole.AccountOwner;
  const isActiveOrganizationAdmin = checkIfUserHasRoleInOrganization(
    activeOrganization?.role,
    ORGANIZATION_ADMIN_AFFILIATED_ROLES
  );
  const {
    features: { disabledMarketForGamemastersOrgCodes },
  } = useConfig();

  const [hasMarketAccess, setHasMarketAccess] = useState(
    canAccessMarket(
      activeOrganization?.role,
      userProfile?.orgCode,
      disabledMarketForGamemastersOrgCodes
    )
  );

  const user = {
    profile: userProfile,
    hasMarketAccess,
    activeOrganization,
  };

  useEffect(() => {
    if (initialIsAffiliationModalOpen === null) {
      setItemToLocalStorage('user', { isAffiliationModalOpen: true });
    }

    if (userProfile) {
      setIsAffiliationModalOpen(
        getFromLocalStorage('user', 'isAffiliationModalOpen') &&
          userProfile &&
          userProfile.organizations?.length > 1
      );
    }
  }, [userProfile, initialIsAffiliationModalOpen]);

  useEffect(() => {
    if (!userProfile) return;

    if (userProfile.language) {
      i18n.changeLanguage(userProfile.language);
    }

    StonlyService.identify({ ...userProfile, language: i18n.language });
  }, [i18n, userProfile]);

  useEffect(() => {
    setHasMarketAccess(
      canAccessMarket(
        activeOrganization?.role,
        userProfile?.orgCode,
        disabledMarketForGamemastersOrgCodes
      )
    );
  }, [
    activeOrganization,
    disabledMarketForGamemastersOrgCodes,
    userProfile?.orgCode,
  ]);

  return (
    <UserDataProvider
      value={{
        user,
        notifications,
        activeOrganization,
        isActiveOrganizationAccountOwner,
        isActiveOrganizationAdmin,
        loadingUserProfile,
        isAffiliationModalOpen,
        setIsAffiliationModalOpen,
      }}
    >
      {children}
    </UserDataProvider>
  );
};
export { UserProvider, useUser };

type ContextType = {
  user: {
    profile?: User;
    hasMarketAccess?: boolean;
  };
  loadingUserProfile: boolean;
  notifications: Notification[];
  activeOrganization?: UserOrganization;
  isActiveOrganizationAccountOwner?: boolean;
  isActiveOrganizationAdmin?: boolean;
  isAffiliationModalOpen: boolean;
  setIsAffiliationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
