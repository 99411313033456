import { StyleRules, DrawerClassKey } from '@material-ui/core';

export const DRAWER_WIDTH = 280;
export const COLLAPSED_DRAWER_WIDTH = 73;
export const COLLAPSED_DRAWER_WIDTH_MOBILE = 57;

export const MuiDrawer: Partial<StyleRules<DrawerClassKey, {}>> = {
  root: {
    width: DRAWER_WIDTH,
  },
  paper: {
    width: DRAWER_WIDTH,
    padding: 4,
  },
};
