import { StyleRules, TableCellClassKey } from '@material-ui/core';
import colors from 'styles/colors.module.scss';

export const MuiTableCell: Partial<StyleRules<TableCellClassKey, {}>> = {
  root: {
    lineHeight: 1.25,
    borderBottom: 'none',
  },
  head: {
    fontWeight: 600,
    color: colors.primary,
    textTransform: 'uppercase',
  },
  body: {
    fontWeight: 600,
    color: colors.black,
  },
  footer: {
    color: colors.primary,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
};
