import { StyleRules, InputBaseClassKey } from '@material-ui/core';

export const MuiInputBase: Partial<StyleRules<InputBaseClassKey, {}>> = {
  root: {
    height: 48,
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'all .1s ease-in-out',
    },
  },
};
