import { StyleRules, ListItemClassKey, hexToRgb } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

import colors from 'styles/colors.module.scss';

const primaryColorInRgb = hexToRgb(colors.primary);

export const MuiListItem: Partial<StyleRules<ListItemClassKey, {}>> = {
  root: {
    height: 56,
    '&$selected': {
      background: `linear-gradient(83.47deg, ${alpha(
        primaryColorInRgb,
        0.2
      )} 9.02%, ${alpha(primaryColorInRgb, 0)} 90.81%)`,
    },
  },
};
