import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { debugContextDevtool } from 'react-context-devtool';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '@fontsource/mulish';

import './index.scss';
import './i18n';

import { Loading } from 'components/Loading/Loading';
import { GlobalContextsWrapper } from 'contexts/GlobalContextsWrapper';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { config } from './config/index';

const container = document.getElementById('root');

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: config.sentry.envName,
  release: getReleaseName(),
});

ReactDOM.render(
  <Suspense fallback={<Loading overlay />}>
    <GlobalContextsWrapper>
      <App />
    </GlobalContextsWrapper>
  </Suspense>,
  container
);

debugContextDevtool(container, {
  disable: process.env.NODE_ENV === 'production',
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

loadHubSpotCode();
loadStonlyCode();

function getReleaseName() {
  if (
    process.env.NODE_ENV !== 'production' ||
    !process.env.REACT_APP_SENTRY_RELEASE
  ) {
    return;
  }

  return process.env.REACT_APP_SENTRY_RELEASE;
}

function loadHubSpotCode() {
  if (config.enableHubSpot === 'true') {
    const HUB_SPOT_JS_URL = '//js.hs-scripts.com/8639274.js';
    const scriptElement = document.createElement('script');

    scriptElement.type = 'text/javascript';
    scriptElement.id = 'hs-script-loader';
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = HUB_SPOT_JS_URL;

    document.body.appendChild(scriptElement);
  }
}

function loadStonlyCode() {
  loadStonlyWidget();
  loadStonlyTracking();
}

function loadStonlyWidget() {
  if (!config.stonlyWid) {
    return;
  }

  const scriptElement = document.createElement('script');

  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.defer = true;
  scriptElement.innerHTML = `!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
    w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).queue=[],(y=t.createElement(o)).async=!0,
    (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
    4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
    (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
    }(window,document,"script","https://stonly.com/js/widget/v2/");`;

  window.STONLY_WID = config.stonlyWid;
  document.head.appendChild(scriptElement);
}

function loadStonlyTracking() {
  if (!config.stonlyTracking) {
    return;
  }

  const scriptElement = document.createElement('script');

  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.defer = true;
  scriptElement.innerHTML = `!function(s,t,o,n,l,y,_){s.stonlyTrack||((_=s.stonlyTrack=function(){
  _._api?_._api.apply(_,arguments):_.queue.push(arguments)
  }).queue=[],(y=t.createElement(o)).async=!0,
  y.src=n,(l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))
  }(window,document,"script","https://stonly.com/js/tracker/stn.js");

  stonlyTrack('init', '${config.stonlyTracking}');`;

  document.head.appendChild(scriptElement);
}
