import React, { Fragment } from 'react';
import { makeStyles, Collapse, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1400,
  },
  typography: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.success.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}));

type Props = { isSubmitted: boolean; create: boolean; message: string };

const ConfirmationCollapse = ({ isSubmitted, create, message }: Props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Collapse in={isSubmitted} collapsedSize="0px" className={classes.root}>
        <Paper
          elevation={4}
          className={create ? classes.success : classes.error}
        >
          <Typography
            data-test="confirmationPopup"
            className={classes.typography}
          >
            {message}
          </Typography>
        </Paper>
      </Collapse>
    </Fragment>
  );
};

export default ConfirmationCollapse;
