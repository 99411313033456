import { OrganisationType, Role } from '@shared/models';
import { Optional } from 'utility-types';

const DEFAULT_ORGANISATION_TYPE = OrganisationType.School;

type RegisterRole = Role.Admin | Role.Gamemaster | Role.Player;

export const initialUserForm = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  role: 'player' as RegisterRole,
  orgCode: '',
  username: '',
  language: '',
  receiveNewsletter: true,
  termsOfAgreement: false,
};

export const initialOrganisationForm = {
  orgName: '',
  orgAddress1: '',
  orgZipCode: '',
  orgCountry: '',
  orgTel: '',
  orgAddress2: '',
  orgCity: '',
  orgStateProvince: '',
  orgType: DEFAULT_ORGANISATION_TYPE,
};

export type OrganisationFormData = Optional<
  typeof initialOrganisationForm,
  'orgTel' | 'orgAddress2' | 'orgStateProvince'
>;

export type UserFormData = Optional<
  typeof initialUserForm,
  'orgCode' | 'username'
>;

const registerDefaultValues = {
  ...initialUserForm,
  ...initialOrganisationForm,
};

export { registerDefaultValues };
