import { StyleRules, ListItemIconClassKey } from '@material-ui/core';
import colors from '../colors.module.scss';

export const MuiListItemIcon: Partial<StyleRules<ListItemIconClassKey, {}>> = {
  root: {
    '& svg path, & svg rect': {
      stroke: colors.gray500,
    },
  },
};
