import { StyleRules, SwitchClassKey } from '@material-ui/core';
import unCheckedIcon from 'assets/icons/un-done.svg';
import checkedIcon from 'assets/icons/checkmark.svg';
import colors from '../colors.module.scss';

export const MuiSwitch: Partial<StyleRules<SwitchClassKey, {}>> = {
  root: {
    width: 64,
    height: 32,
    padding: 0,
  },
  thumb: {
    width: 24,
    height: 24,
  },
  colorPrimary: {
    '&$checked $thumb': {
      color: colors.white,
    },
  },
  switchBase: {
    padding: 4,
    '&$checked': {
      transform: 'translateX(32px)',
      opacity: 1,
      '& + $track': {
        opacity: 1,
      },
      '& $thumb::before': {
        left: -26,
        backgroundImage: `url(${checkedIcon})`,
      },
    },
    '& $thumb::before': {
      content: '""',
      left: 34,
      position: 'absolute',
      backgroundImage: `url(${unCheckedIcon})`,
      width: 24,
      height: 24,
      transition: '150ms',
    },
    '& + $track': {
      backgroundColor: colors.secondary,
    },
  },
  track: {
    borderRadius: 22,
    opacity: 1,
  },
};
