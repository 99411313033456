import { firestore } from '../types/firebase';
import { Task } from './Task';

export type DenormalizedTasks = Record<string, DenormalizedTask>;

export type DenormalizedTask = Pick<
  Task,
  'uid' | 'title' | 'responseType' | 'tags' | 'updated' | 'alternatives'
> & {
  active?: boolean;
};
export interface Collection {
  active: boolean;
  created: firestore.Timestamp;
  languageCode?: string;
  shareLevel: CollectionShareLevel;
  tags: string[];
  tasks: DenormalizedTasks;
  title: string;
  updated: firestore.Timestamp;
  userFirstName: string;
  userId: string;
  userLastName: string;
  orgId?: string;
  orgCode?: string;
  uid: string;
  copiedFrom?: string;
  copiedAt?: firestore.Timestamp;
  updatedByBeAt?: firestore.Timestamp;
  marketDownloadsCount?: number;
  tasksCount: number;
  thumbnailUrl?: string;
  isFavorite?: boolean;
}

export enum CollectionShareLevel {
  Private = 'private',
  Internal = 'internal',
  Public = 'public',
}
